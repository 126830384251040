import img1 from "../images/ourValuesIcons/value_1.png";
import img2 from "../images/ourValuesIcons/value_2.png";
import img3 from "../images/ourValuesIcons/value_3.png";
import img4 from "../images/ourValuesIcons/value_4.png";
import img5 from "../images/ourValuesIcons/value_5.png";
import img6 from "../images/ourValuesIcons/value_6.png";

const boxesContent = [
  { img: img1, up: "PROJEKT", down: "ZIELONA DROGA" },
  { img: img2, up: "PROJEKT", down: "BLIŻEJ NATURY" },
  { img: img3, up: "PROJEKT", down: "ZIELONA DROGA" },
  { img: img4, up: "PROJEKT", down: "EKO CERTYFIKAT" },
  { img: img5, up: "PROJEKT", down: "EKO INICJATYWA" },
  { img: img6, up: "PROJEKT", down: "EKO DOM" },
];

export default boxesContent;
